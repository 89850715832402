// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgLargeComputer = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={512}
    height={512}
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="m67 80 385-4v238l60 81-14 38-483-3-15-36 63-80 4-234Z" fill="#000" />
    <path
      d="M79 108c0-5.601 0-8.4 1.09-10.54a10.001 10.001 0 0 1 4.37-4.37C86.6 92 89.4 92 95 92h323c5.601 0 8.401 0 10.54 1.09a10 10 0 0 1 4.37 4.37C434 99.6 434 102.4 434 108v212H79V108Z"
      fill="#000"
    />
    <path d="M79 92h355v221l-355-5V92ZM25 388l465 2-56-70H79l-54 68ZM25 399l465-4-8 19H31l-6-15Z" fill="#B5C1C9" />
    <path d="M481.031 414 489 395l-260 2 252.031 17Z" fill="#fff" />
    <path d="M110 309h293l5 16-305 3 7-19Z" fill="#000" />
    <path d="M116 314h280l4 6-287 1 3-7Z" fill="#DAE2EB" />
    <path d="M318 314h78l4 6-82-6Z" fill="#fff" />
    <path fill="#000" d="M87 104h339v192H87z" />
    <path d="m96 111 320 1v177l-315-4-5-174Z" fill="#DAE2EB" />
    <path d="m336 111.5 80 .5v177l-64-1 15.5-41-39.5-49.5 8-86Z" fill="#fff" />
    <path
      d="M190.234 366.106c.276-.758.414-1.138.657-1.418.215-.247.487-.438.793-.555.346-.133.75-.133 1.557-.133h128.315c.875 0 1.313 0 1.679.151.324.134.606.35.82.627.243.313.358.736.588 1.58l4.255 15.6c.374 1.374.562 2.061.403 2.604a2 2 0 0 1-.872 1.142c-.482.296-1.194.296-2.619.296H187.569c-1.537 0-2.306 0-2.803-.323a1.998 1.998 0 0 1-.859-1.227c-.134-.577.129-1.299.654-2.744l5.673-15.6Z"
      fill="#000"
    />
    <path
      d="M194.316 373.28c.245-.818.368-1.227.611-1.53a1.99 1.99 0 0 1 .812-.604c.36-.146.788-.146 1.642-.146h118.996c.934 0 1.401 0 1.784.167.338.148.628.386.839.688.24.343.331.801.514 1.717l.72 3.6c.263 1.312.394 1.968.216 2.482-.155.45-.467.83-.878 1.071-.468.275-1.137.275-2.475.275H196.301c-1.458 0-2.187 0-2.673-.304a2 2 0 0 1-.869-1.168c-.151-.553.058-1.251.477-2.648l1.08-3.6Z"
      fill="#DAE2EB"
    />
    <path
      d="M316.156 372.305c1.006-.108 1.509-.162 1.929-.017.371.127.696.36.935.671.271.352.381.846.601 1.833l.514 2.314c.296 1.331.443 1.997.271 2.52a2.003 2.003 0 0 1-.876 1.093c-.473.281-1.155.281-2.519.281H235l81.156-8.695Z"
      fill="#fff"
    />
    <path
      d="M79.56 333.92c.528-.704.792-1.056 1.127-1.31.296-.225.632-.393.99-.495.403-.115.843-.115 1.723-.115h351.13c.904 0 1.357 0 1.77.121.366.107.708.284 1.008.519.339.266.602.634 1.128 1.37l16.757 23.46c.584.818.876 1.226.863 1.567a1.001 1.001 0 0 1-.388.755c-.27.208-.772.208-1.777.208H63.2c-1.03 0-1.545 0-1.817-.213a1 1 0 0 1-.383-.766c-.007-.345.302-.757.92-1.581l17.64-23.52Z"
      fill="#000"
    />
    <path
      d="M83.583 339.204c.517-.805.776-1.207 1.123-1.499a2.98 2.98 0 0 1 1.046-.571c.433-.134.911-.134 1.869-.134H429.53c.904 0 1.357 0 1.77.121.366.107.708.284 1.008.519.339.266.602.634 1.128 1.37l6.757 9.46c.584.818.876 1.226.863 1.567a1.001 1.001 0 0 1-.388.755c-.27.208-.772.208-1.777.208H78.931c-.955 0-1.432 0-1.699-.199a1 1 0 0 1-.398-.73c-.023-.332.235-.733.75-1.536l5.999-9.331Z"
      fill="#DAE2EB"
    />
    <circle cx={256} cy={99} r={4} fill="#000" />
    <circle cx={256.5} cy={98.5} r={2.5} fill="#DAE2EB" />
    <path
      d="M255.164 140c-33.567 0-60.778 27.211-60.778 60.778s27.211 60.779 60.778 60.779 60.778-27.212 60.778-60.779c0-33.567-27.211-60.778-60.778-60.778Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M294.426 272.96c31.053-4.364 27.868-41.054 23.503-72.106-4.364-31.053-32.653-49.69-63.706-45.325-31.052 4.364-58.264 36.916-53.9 67.969 4.365 31.052 63.05 53.826 94.103 49.462Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.647 256.027c-31.147-.697-56.104-25.32-55.743-54.997.36-29.677 25.902-53.17 57.048-52.473 31.147.697 56.104 25.32 55.744 54.997-.361 29.677-25.903 53.17-57.049 52.473Z"
      fill="#B5C1C9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.906 204.538c.447 28.693 25.549 51.407 56.068 50.733 30.518-.674 54.895-24.48 54.448-53.173a49.464 49.464 0 0 0-.44-5.862c-.019 28.33-24.224 51.696-54.455 52.363-28.407.628-52.122-19.01-55.627-44.871 0 .269 0 .539.006.81Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M302.095 197.421c-.379-22.072-21.699-39.544-47.619-39.026-25.919.519-46.623 18.831-46.244 40.903a34.62 34.62 0 0 0 .374 4.509c.016-21.792 20.574-39.766 46.249-40.28 24.127-.482 44.269 14.624 47.246 34.517 0-.207 0-.415-.006-.623Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.647 153.993c23.158 0 42.6 15.589 47.846 36.576-6.709-18.717-25.005-32.152-46.504-32.152-27.154 0-49.133 21.433-49.093 47.872a46.842 46.842 0 0 0 1.394 11.296 46.742 46.742 0 0 1-2.736-15.72c-.04-26.439 21.94-47.872 49.093-47.872Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m276.925 204.741-33.44 19.148c-1.801 1.075-4.056-.35-5.05-3.182a8.006 8.006 0 0 1-.429-2.067l-1.011-40.507c.075-3.029 2.262-5.43 4.847-5.363.6.016 1.185.167 1.722.447l35.164 21.358c1.627 1.199 1.878 4.427.628 7.209-.6 1.363-1.474 2.43-2.431 2.957Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m274.905 208.195-31.698 17.987c-1.712 1.01-3.849-.333-4.787-3a7.466 7.466 0 0 1-.404-1.946l-1.021-38.124c.058-2.85 2.092-5.108 4.51-5.043a3.677 3.677 0 0 1 1.613.422l33.349 20.136c1.567 1.13 1.859 4.169.711 6.786-.55 1.283-1.369 2.287-2.273 2.782Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M241.915 189.311v25.963a4.543 4.543 0 0 0 6.8 3.941l22.659-12.982a4.542 4.542 0 0 0 0-7.882l-22.659-12.982a4.542 4.542 0 0 0-6.8 3.942Z"
      fill="#fff"
    />
    <path d="m50 65 14-49 14 49 42 15-42 15-14 49-14-49L8 80l42-15Z" fill="#000" />
    <path d="m56 72 8-40 8 40 32 8-32 8-8 40-8-40-32-8 32-8Z" fill="#B5C1C9" />
    <path d="M61 81.5 64 39l5 36 31 5-39 1.5Z" fill="#fff" />
  </svg>
))
SvgLargeComputer.displayName = 'SvgLargeComputer'
const Memo = memo(SvgLargeComputer)
export { Memo as SvgLargeComputer }
