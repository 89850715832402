// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgMintNftVideoTile = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={320}
    height={260}
    viewBox="0 0 320 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_2424_11523)">
      <path fill="#C2E0FF" fillOpacity={0.2} d="M0-136h320V44.005H0z" />
      <g clipPath="url(#clip1_2424_11523)">
        <path fill="#272D33" d="M0-136h320V44.005H0z" />
        <g filter="url(#filter0_b_2424_11523)" clipPath="url(#clip2_2424_11523)">
          <rect x={275} y={12} width={37} height={24} rx={2} fill="#101214" fillOpacity={0.75} />
          <rect x={281} y={19} width={25} height={10} rx={5} fill="#C2E0FF" fillOpacity={0.2} />
        </g>
      </g>
      <rect y={68.005} width={220} height={10} rx={5} fill="#C2E0FF" fillOpacity={0.2} />
      <rect y={90.005} width={129} height={10} rx={5} fill="#C2E0FF" fillOpacity={0.2} />
      <rect y={122.005} width={158} height={8} rx={4} fill="#C2E0FF" fillOpacity={0.2} />
      <rect x={288} y={60.005} width={32} height={32} rx={16} fill="#BCD5FA" fillOpacity={0.08} />
      <path d="M303 69.005h2v2h-2v-2ZM303 75.005h2v2h-2v-2ZM303 81.005h2v2h-2v-2Z" fill="#F4F6F8" />
      <g filter="url(#filter1_dd_2424_11523)">
        <rect x={128} y={92} width={192} height={168} rx={2} fill="#272D33" />
        <circle cx={152} cy={116} r={6} fill="#C2E0FF" fillOpacity={0.2} />
        <rect x={172} y={112} width={91} height={8} rx={4} fill="#C2E0FF" fillOpacity={0.2} />
        <circle cx={152} cy={156} r={6} fill="#C2E0FF" fillOpacity={0.2} />
        <rect x={172} y={152} width={67} height={8} rx={4} fill="#C2E0FF" fillOpacity={0.2} />
        <path fill="#BBD9F6" fillOpacity={0.13} d="M128 176h192v40H128z" />
        <path
          d="M152 189h-1v4h-5v2a2 2 0 0 1 2 2v1h7.6a2.4 2.4 0 0 0 2.4-2.4v-.6a6 6 0 0 0-6-6ZM150 203a2 2 0 1 0 .001-3.999A2 2 0 0 0 150 203ZM156 203a2 2 0 1 0 .001-3.999A2 2 0 0 0 156 203ZM173.005 190.818h2.257l3.023 7.378h.119l3.023-7.378h2.257V201h-1.77v-6.995h-.094l-2.814 6.965h-1.322l-2.814-6.98h-.095V201h-1.77v-10.182ZM185.683 201v-7.636h1.8V201h-1.8Zm.905-8.72c-.285 0-.53-.095-.736-.284a.914.914 0 0 1-.308-.691c0-.271.103-.502.308-.691.206-.192.451-.288.736-.288.288 0 .534.096.736.288.205.189.308.42.308.691a.914.914 0 0 1-.308.691 1.04 1.04 0 0 1-.736.284Zm4.615 4.246V201h-1.799v-7.636h1.72v1.297h.089a2.19 2.19 0 0 1 .84-1.019c.388-.252.867-.378 1.437-.378.527 0 .986.113 1.377.338.395.226.7.552.915.98.219.427.327.946.323 1.556V201h-1.799v-4.584c0-.51-.133-.91-.398-1.198-.262-.288-.625-.432-1.089-.432-.315 0-.595.069-.84.208a1.445 1.445 0 0 0-.572.592c-.136.258-.204.572-.204.94Zm10.615-3.162v1.392h-4.39v-1.392h4.39Zm-3.306-1.83h1.8v7.169c0 .242.036.428.109.557a.588.588 0 0 0 .298.258c.123.047.259.07.408.07a2.231 2.231 0 0 0 .527-.07l.303 1.407c-.096.034-.233.07-.412.11a3.32 3.32 0 0 1-.647.069 2.93 2.93 0 0 1-1.218-.203 1.9 1.9 0 0 1-.86-.706c-.209-.319-.311-.716-.308-1.194v-7.467Zm16.871-.716V201h-1.641l-4.798-6.935h-.084V201h-1.845v-10.182h1.651l4.793 6.941h.089v-6.941h1.835ZM217.462 201v-10.182h6.523v1.546h-4.678v2.765h4.231v1.546h-4.231V201h-1.845Zm7.826-8.636v-1.546h8.124v1.546h-3.147V201h-1.83v-8.636h-3.147Z"
          fill="#F4F6F8"
        />
        <circle cx={152} cy={236} r={6} fill="#C2E0FF" fillOpacity={0.2} />
        <rect x={172} y={232} width={67} height={8} rx={4} fill="#C2E0FF" fillOpacity={0.2} />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2424_11523">
        <path fill="#fff" d="M0 0h320v260H0z" />
      </clipPath>
      <clipPath id="clip1_2424_11523">
        <path fill="#fff" transform="translate(0 -136)" d="M0 0h320v180.005H0z" />
      </clipPath>
      <clipPath id="clip2_2424_11523">
        <path fill="#fff" transform="translate(0 -136)" d="M0 0h320v180.005H0z" />
      </clipPath>
      <filter
        id="filter0_b_2424_11523"
        x={243}
        y={-20}
        width={101}
        height={88}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={16} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2424_11523" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2424_11523" result="shape" />
      </filter>
      <filter
        id="filter1_dd_2424_11523"
        x={96}
        y={76}
        width={256}
        height={232}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={16} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2424_11523" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="effect1_dropShadow_2424_11523" result="effect2_dropShadow_2424_11523" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_2424_11523" result="shape" />
      </filter>
    </defs>
  </svg>
))
SvgMintNftVideoTile.displayName = 'SvgMintNftVideoTile'
const Memo = memo(SvgMintNftVideoTile)
export { Memo as SvgMintNftVideoTile }
