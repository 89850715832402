// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgBenefitCardPatternXxs2 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={144}
    height={144}
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_1505_4343)">
      <path d="M108 143.987C88.36 143.466 72.534 127.64 72.013 108H108v35.987Z" fill="#C2E0FF" fillOpacity={0.2} />
    </g>
    <g clipPath="url(#clip1_1505_4343)" fill="#BCD5FA" fillOpacity={0.08}>
      <path d="M108 104h36v4h-36v-4ZM108 72h36v4h-36v-4ZM144 80h-36v4h36v-4ZM108 96h36v4h-36v-4ZM144 88h-36v4h36v-4Z" />
    </g>
    <path d="M0 144h36v-36H0v36Z" fill="#BCD5FA" fillOpacity={0.08} />
    <path
      d="M108 72h-4v-4h4v4ZM96 52h4v4h-4v-4ZM92 52v4h-4v-4h4ZM92 48v4h4v-4h4v-4h-4v4h-4ZM88 48v-4h4v4h-4ZM84 48h4v4h-4v-4ZM84 56h-4v-4h4v4ZM84 60h-4v4h4v-4ZM84 60v-4h4v4h-4ZM84 48v-4h-4v4h4Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 68h4v-4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4h4v4h-4v4h4v4h-4v4h4v4h-4v4h4v4h-4v4h4v-4h4v4h4v-4h4v4h4v-4h4v4h4v-4Zm0-4v4h-4v-4h4Zm0-4v4h4v-4h-4Zm-4 0v4h-4v-4h4Zm0-4v4h4v-4h4v-4h-4v-4h4v-4h-4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4h-4v4h4v4h-4v4h4v4h-4v4h4v4h4v-4h4v4h4v-4h-4v-4h4v-4h4Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      d="M36 72h4v4h-4v-4ZM48 92h-4v-4h4v4ZM52 92v-4h4v4h-4ZM52 96v-4h-4v4h-4v4h4v-4h4ZM56 96v4h-4v-4h4ZM60 96h-4v-4h4v4ZM60 88h4v4h-4v-4ZM60 84h4v-4h-4v4ZM60 84v4h-4v-4h4ZM60 96v4h4v-4h-4Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 76h-4v4h-4v4h4v4h-4v4h4v4h-4v4h4v4h-4v4h4v-4h4v4h4v-4h4v4h4v-4h4v4h4v-4h4v4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4h-4v-4h-4v4Zm0 4v-4h4v4h-4Zm0 4v-4h-4v4h4Zm4 0v-4h4v4h-4Zm0 4v-4h-4v4h-4v4h4v4h-4v4h4v4h4v-4h4v4h4v-4h4v4h4v-4h4v-4h-4v-4h4v-4h-4v-4h4v-4h-4v-4h-4v4h-4v-4h-4v4h4v4h-4v4h-4Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path d="M144 0h-36v36h36V0Z" fill="#BCD5FA" fillOpacity={0.08} />
    <g clipPath="url(#clip2_1505_4343)" fill="#BCD5FA" fillOpacity={0.08}>
      <path d="M36 40H0v-4h36v4ZM36 72H0v-4h36v4ZM0 64h36v-4H0v4ZM36 48H0v-4h36v4ZM0 56h36v-4H0v4Z" />
    </g>
    <g clipPath="url(#clip3_1505_4343)">
      <path d="M36 .013C55.64.534 71.466 16.36 71.987 36H36V.013Z" fill="#C2E0FF" fillOpacity={0.2} />
    </g>
    <defs>
      <clipPath id="clip0_1505_4343">
        <path fill="#fff" transform="rotate(-180 54 72)" d="M0 0h36v36H0z" />
      </clipPath>
      <clipPath id="clip1_1505_4343">
        <path fill="#fff" transform="rotate(-180 72 54)" d="M0 0h36v36H0z" />
      </clipPath>
      <clipPath id="clip2_1505_4343">
        <path fill="#fff" transform="translate(0 36)" d="M0 0h36v36H0z" />
      </clipPath>
      <clipPath id="clip3_1505_4343">
        <path fill="#fff" transform="translate(36)" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgBenefitCardPatternXxs2.displayName = 'SvgBenefitCardPatternXxs2'
const Memo = memo(SvgBenefitCardPatternXxs2)
export { Memo as SvgBenefitCardPatternXxs2 }
