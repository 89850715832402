// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const Svg404PatternTopRight = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={360}
    height={288}
    viewBox="0 0 360 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_1110_4101)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M144 8H72V0h72v8ZM144 72H72v-8h72v8ZM72 56h72v-8H72v8ZM144 24H72v-8h72v8ZM72 40h72v-8H72v8Z" />
    </g>
    <g clipPath="url(#clip1_1110_4101)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M288 152h-72v-8h72v8ZM288 216h-72v-8h72v8ZM216 200h72v-8h-72v8ZM288 168h-72v-8h72v8ZM216 184h72v-8h-72v8Z" />
    </g>
    <path
      d="M288 72h8v8h-8v-8ZM312 112h-8v-8h8v8ZM320 112v-8h8v8h-8ZM320 120v-8h-8v8h-8v8h8v-8h8ZM328 120v8h-8v-8h8ZM336 120h-8v-8h8v8ZM336 104h8v8h-8v-8ZM336 96h8v-8h-8v8ZM336 96v8h-8v-8h8ZM336 120v8h8v-8h-8Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M304 80h-8v8h-8v8h8v8h-8v8h8v8h-8v8h8v8h-8v8h8v-8h8v8h8v-8h8v8h8v-8h8v8h8v-8h8v8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v8h-8v-8h-8v8h-8v-8h-8v8h-8v-8h-8v8Zm0 8v-8h8v8h-8Zm0 8v-8h-8v8h8Zm8 0v-8h8v8h-8Zm0 8v-8h-8v8h-8v8h8v8h-8v8h8v8h8v-8h8v8h8v-8h8v8h8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h-8v8h-8v-8h-8v8h8v8h-8v8h-8ZM288.154 66.36V72h-5.641L216.154 5.64V0h5.639l66.361 66.36Zm-72-49.08v11.255L259.62 72h11.254l-54.72-54.72Zm0 33.64V39.6l32.4 32.4h-11.321l-21.079-21.08Zm0 21.08h10.08l-10.08-10.08V72Zm17.28-72h11.254l43.466 43.465V54.72L233.434 0Zm33.64 0h-11.32l32.4 32.4V21.08L267.074 0Zm21.08 10.08L278.074 0h10.08v10.08Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip2_1110_4101)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M144 144.027v8.003c-34.861 1.037-62.933 29.109-63.97 63.97h-8.004c1.043-39.28 32.694-70.931 71.974-71.973Z" />
      <path d="M88.034 216c1.031-30.442 25.523-54.934 55.966-55.966v8.005c-26.024 1.024-46.937 21.937-47.96 47.961h-8.006Z" />
      <path d="M104 216c0-22.091 17.909-40 40-40v8c-17.673 0-32 14.327-32 32h-8Z" />
      <path d="M120 216c0-13.255 10.745-24 24-24v8c-8.837 0-16 7.164-16 16h-8ZM136 216a8 8 0 0 1 8-8v8h-8Z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.154 138.36V144h-5.64L.153 77.64V72h5.64l66.36 66.36Zm-72-49.08v11.254L43.62 144h11.254L.154 89.28Zm0 33.64V111.6l32.4 32.4h-11.32L.153 122.92Zm0 21.08h10.08L.154 133.92V144Zm17.28-72h11.254l43.466 43.465v11.255L17.434 72Zm33.64 0h-11.32l32.4 32.4V93.08L51.074 72Zm21.08 10.08L62.074 72h10.08v10.08Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip3_1110_4101)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M288 216.027v8.003c34.861 1.037 62.933 29.109 63.97 63.97h8.003c-1.042-39.28-32.693-70.931-71.973-71.973Z" />
      <path d="M343.966 288c-1.031-30.442-25.523-54.934-55.966-55.966v8.005c26.024 1.024 46.937 21.937 47.961 47.961h8.005Z" />
      <path d="M328 288c0-22.091-17.909-40-40-40v8c17.673 0 32 14.327 32 32h8Z" />
      <path d="M312 288c0-13.255-10.745-24-24-24v8c8.837 0 16 7.164 16 16h8ZM296 288a8 8 0 0 0-8-8v8h8Z" />
    </g>
    <g clipPath="url(#clip4_1110_4101)">
      <path d="M215.974 144C214.931 104.72 183.28 73.069 144 72.026V144h71.974Z" fill="#BBD9F6" fillOpacity={0.13} />
    </g>
    <defs>
      <clipPath id="clip0_1110_4101">
        <path fill="#fff" transform="translate(72)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip1_1110_4101">
        <path fill="#fff" transform="translate(216 144)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip2_1110_4101">
        <path fill="#fff" transform="matrix(-1 0 0 1 144 144)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip3_1110_4101">
        <path fill="#fff" transform="translate(288 216)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip4_1110_4101">
        <path fill="#fff" transform="matrix(0 -1 -1 0 216 144)" d="M0 0h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
))
Svg404PatternTopRight.displayName = 'Svg404PatternTopRight'
const Memo = memo(Svg404PatternTopRight)
export { Memo as Svg404PatternTopRight }
