// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgBgPattern = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={569}
    height={462}
    viewBox="0 0 569 462"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_1008_6545)" fill="#181C20">
      <path d="M136.79 229.968H21.826V115.004H136.79v114.964Zm345.352 230.01H367.178V345.014h114.964v114.964Z" />
      <path d="M481.212 459.978h12.781v-12.782h-12.781v12.782Zm13.484-12.816h12.782v-12.781h-12.782v12.781Zm13.491 12.816h12.781v-12.782h-12.781v12.782Zm-26.975-25.632h12.781v-12.782h-12.781v12.782Zm26.975 0h12.781v-12.782h-12.781v12.782Zm10.797 12.816h12.782v-12.781h-12.782v12.781Zm-24.288-25.632h12.782v-12.78h-12.782v12.78Zm-13.484-12.815h12.781v-12.782h-12.781v12.782Zm26.975 0h12.781v-12.782h-12.781v12.782Zm10.797 12.815h12.782v-12.78h-12.782v12.78Zm-24.288-25.631h12.782v-12.781h-12.782v12.781Zm-13.484-12.143h12.781v-12.781h-12.781v12.781Zm26.975 0h12.781v-12.781h-12.781v12.781Z" />
      <path d="M518.984 395.899h12.782v-12.781h-12.782v12.781Zm-24.288-24.959h12.782v-12.781h-12.782v12.781Zm-13.484-12.816h12.781v-12.781h-12.781v12.781Zm26.975 0h12.781v-12.781h-12.781v12.781Zm10.797 12.816h12.782v-12.781h-12.782v12.781Zm13.486 89.038h12.781v-12.782H532.47v12.782Zm13.49-12.816h12.781v-12.781H545.96v12.781Zm13.49 12.816h12.781v-12.782H559.45v12.782Zm-26.98-25.632h12.781v-12.782H532.47v12.782Zm26.98 0h12.781v-12.782H559.45v12.782Zm-13.49-12.816h12.781v-12.78H545.96v12.78Zm-13.49-12.815h12.781v-12.782H532.47v12.782Zm26.98 0h12.781v-12.782H559.45v12.782Zm-13.49-12.816h12.781v-12.781H545.96v12.781Zm-13.49-12.143h12.781v-12.781H532.47v12.781Zm26.98 0h12.781v-12.781H559.45v12.781Zm-13.49-12.816h12.781v-12.781H545.96v12.781Zm-13.49-12.816h12.781v-12.781H532.47v12.781Zm26.98 0h12.781v-12.781H559.45v12.781ZM455.504 115.299H442.73V.335h12.774v114.964Zm-24.289 0h-12.773V.335h12.773v114.964Zm-26.975 0h-12.773V.335h12.773v114.964Zm-24.287 0h-12.775V.335h12.775v114.964Zm102.533 0h-12.781V.335h12.781v114.964Z" />
      <path d="M481.55 89.669h114.964V76.895H481.55v12.774Zm0-25.632h114.964V51.263H481.55v12.774Zm0-25.632h114.964V25.632H481.55v12.773Zm113.917-25.631H480.503V0h114.964v12.774ZM481.55 115.299h114.964v-12.782H481.55v12.782Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M480.503 115.678v12.774c7.055 0 12.773-5.719 12.773-12.774h-12.773Zm0 25.548V154c21.164 0 38.321-17.157 38.321-38.322H506.05c0 14.11-11.438 25.548-25.547 25.548Zm0 38.321v-12.774c28.219 0 51.095-22.875 51.095-51.095h12.774c0 35.275-28.596 63.869-63.869 63.869Zm0 12.774v12.774c49.383 0 89.416-40.033 89.416-89.417h-12.774c0 42.329-34.314 76.643-76.642 76.643Zm0 25.548v12.773c63.492 0 114.964-51.471 114.964-114.964h-12.774c0 56.438-45.752 102.191-102.19 102.191Zm1.639 357.45v-12.774c-7.054 0-12.773 5.719-12.773 12.774h12.773Zm0-25.548v-12.774c-21.164 0-38.321 17.157-38.321 38.322h12.774c0-14.11 11.438-25.548 25.547-25.548Zm0-38.321v12.773c-28.219 0-51.095 22.876-51.095 51.096h-12.773c0-35.274 28.595-63.869 63.868-63.869Zm0-12.774v-12.774c-49.383 0-89.416 40.033-89.416 89.417H405.5c0-42.329 34.314-76.643 76.642-76.643Zm0-25.548v-12.773c-63.492 0-114.964 51.471-114.964 114.964h12.774c0-56.439 45.753-102.191 102.19-102.191ZM137.843 115.299h12.774c0-7.055-5.719-12.774-12.774-12.774v12.774Zm25.548 0h12.773c0-21.165-17.157-38.322-38.321-38.322v12.774c14.109 0 25.548 11.439 25.548 25.548Zm38.321 0h-12.774c0-28.22-22.876-51.095-51.095-51.095V51.43c35.274 0 63.869 28.595 63.869 63.869Zm12.774 0h12.773c0-49.384-40.033-89.417-89.416-89.417v12.774c42.328 0 76.643 34.314 76.643 76.643Zm25.547 0h12.774C252.807 51.806 201.336.335 137.843.335v12.773c56.438 0 102.19 45.752 102.19 102.191ZM366.131.335h-12.773c0 7.055 5.718 12.774 12.773 12.774V.335Zm-25.547 0H327.81c0 21.164 17.157 38.321 38.321 38.321V25.882c-14.109 0-25.547-11.438-25.547-25.547Zm-38.322 0h12.774c0 28.219 22.876 51.095 51.095 51.095v12.774c-35.273 0-63.869-28.595-63.869-63.87v.001Zm-12.773 0h-12.774c0 49.383 40.033 89.416 89.416 89.416V76.977c-42.328 0-76.642-34.314-76.642-76.642Zm-25.548 0h-12.774c0 63.492 51.472 114.964 114.964 114.964v-12.774c-56.438 0-102.19-45.752-102.19-102.19Z"
      />
      <path d="M482.142 115.004v114.964H367.178c0-63.493 51.472-114.964 114.964-114.964Zm0 130.925-15.584-15.584h15.584v15.584Zm0 18.075v18.074l-51.733-51.733h18.075l33.658 33.659Zm0 36.149v18.075l-87.883-87.883h18.075l69.808 69.808Zm-114.964 45.156v-15.585l15.584 15.585h-15.584Zm51.734 0h-18.075l-33.659-33.66v-18.074l51.734 51.734Zm36.15 0h-18.075L367.178 275.5v-18.075l87.884 87.884Zm-78.878-114.964h-9.006v9.005l105.959 105.959h9.005v-9.006L376.184 230.345Z" />
    </g>
    <defs>
      <clipPath id="clip0_1008_6545">
        <path fill="#fff" d="M0 0h569v462H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgBgPattern.displayName = 'SvgBgPattern'
const Memo = memo(SvgBgPattern)
export { Memo as SvgBgPattern }
