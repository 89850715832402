// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgOtherSignInMobileDialogPatterns = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={504}
    height={216}
    viewBox="0 0 504 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M144 0h8v8h-8V0ZM168 40h-8v-8h8v8ZM176 40v-8h8v8h-8ZM176 48v-8h-8v8h-8v8h8v-8h8ZM184 48v8h-8v-8h8ZM192 48h-8v-8h8v8ZM192 32h8v8h-8v-8ZM192 24h8v-8h-8v8ZM192 24v8h-8v-8h8ZM192 48v8h8v-8h-8Z"
      fill="#4038FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160 8h-8v8h-8v8h8v8h-8v8h8v8h-8v8h8v8h-8v8h8v-8h8v8h8v-8h8v8h8v-8h8v8h8v-8h8v8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8V8h8V0h-8v8h-8V0h-8v8h-8V0h-8v8h-8V0h-8v8Zm0 8V8h8v8h-8Zm0 8v-8h-8v8h8Zm8 0v-8h8v8h-8Zm0 8v-8h-8v8h-8v8h8v8h-8v8h8v8h8v-8h8v8h8v-8h8v8h8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8V8h-8v8h-8V8h-8v8h8v8h-8v8h-8Z"
      fill="#4038FF"
    />
    <g clipPath="url(#clip0_1555_4318)">
      <path d="M144 143.974C104.72 142.931 73.069 111.28 72.026 72H144v71.974Z" fill="#BBD9F6" fillOpacity={0.13} />
    </g>
    <g clipPath="url(#clip1_1555_4318)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M8 0v72H0V0h8ZM72 0v72h-8V0h8ZM56 72V0h-8v72h8ZM24 0v72h-8V0h8ZM40 72V0h-8v72h8Z" />
    </g>
    <g clipPath="url(#clip2_1555_4318)">
      <path d="M503.974 72C502.931 32.72 471.28 1.069 432 .026V72h71.974Z" fill="#BBD9F6" fillOpacity={0.13} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M359.846 138.36V144h5.641l66.359-66.36V72h-5.639l-66.361 66.36Zm72-49.08v11.254L388.38 144h-11.254l54.72-54.72Zm0 33.64V111.6l-32.4 32.4h11.321l21.079-21.08Zm0 21.08h-10.08l10.08-10.08V144Zm-17.28-72h-11.254l-43.466 43.465v11.255L414.566 72Zm-33.64 0h11.32l-32.4 32.4V93.08L380.926 72Zm-21.08 10.08L369.926 72h-10.08v10.08Z"
      fill="#fff"
    />
    <g clipPath="url(#clip3_1555_4318)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M360 215.973v-8.003c-34.861-1.037-62.933-29.109-63.97-63.97h-8.003c1.042 39.28 32.693 70.931 71.973 71.973Z" />
      <path d="M304.034 144c1.031 30.442 25.523 54.934 55.966 55.966v-8.005c-26.024-1.024-46.937-21.937-47.961-47.961h-8.005Z" />
      <path d="M320 144c0 22.091 17.909 40 40 40v-8c-17.673 0-32-14.327-32-32h-8Z" />
      <path d="M336 144c0 13.255 10.745 24 24 24v-8c-8.837 0-16-7.164-16-16h-8ZM352 144a8 8 0 0 0 8 8v-8h-8Z" />
    </g>
    <g clipPath="url(#clip4_1555_4318)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M144 208h72v8h-72v-8ZM144 144h72v8h-72v-8ZM216 160h-72v8h72v-8ZM144 192h72v8h-72v-8ZM216 176h-72v8h72v-8Z" />
    </g>
    <defs>
      <clipPath id="clip0_1555_4318">
        <path fill="#fff" transform="rotate(-180 72 72)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip1_1555_4318">
        <path fill="#fff" transform="rotate(-90 36 36)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip2_1555_4318">
        <path fill="#fff" transform="matrix(0 -1 -1 0 504 72)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip3_1555_4318">
        <path fill="#fff" transform="rotate(-180 180 108)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip4_1555_4318">
        <path fill="#fff" transform="rotate(-180 108 108)" d="M0 0h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgOtherSignInMobileDialogPatterns.displayName = 'SvgOtherSignInMobileDialogPatterns'
const Memo = memo(SvgOtherSignInMobileDialogPatterns)
export { Memo as SvgOtherSignInMobileDialogPatterns }
