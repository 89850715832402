// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSmallTokens = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={256}
    height={256}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_1412_4139)">
      <path
        d="M186.771 56.289c33.542 14.525 43.228 74.45 23.198 128.166-9.472 25.4-24.224 46.859-41.32 60.523-7.234 5.782-14.554 9.091-21.747 10.383-4.903.882-8.602.774-12.867.147-12.413-.242-22.017-1.426-28.811-3.552-7.041-2.203-14.243-6.845-21.607-13.924-21.361-22.474-25.18-68.958-8.414-113.921 20.03-53.716 62.665-87.091 96.207-72.566 4.241 1.837 10.512 2.644 15.361 4.744Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.603 244.367c10.438-1.618 35.078-21.209 49.154-50.303 13.06-26.994 16.15-62.908 12.136-81.86-4.725-22.312-9.717-35.756-28.952-46.452 4.393.087 9.365 2.053 13.498 3.76 28.996 11.968 37.799 62.969 20.64 111.312-8.455 23.821-21.828 43.063-36.688 55.024-13.135 10.572-18.343 10.122-22.06 9.614-4.381-.599-6.957-.964-7.728-1.095Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.927 173.628c20.552-47.574 13.922-96.186-14.809-108.578-28.73-12.392-68.681 16.13-89.233 63.703-20.552 47.574-13.922 96.186 14.809 108.578 28.73 12.392 68.682-16.129 89.233-63.703Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.927 173.628c20.552-47.574 13.921-96.186-14.809-108.578-28.731-12.392-68.682 16.13-89.234 63.703-20.551 47.574-13.921 96.186 14.81 108.578 28.73 12.392 68.681-16.129 89.233-63.703Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.757 73.198c-22.755-.356-47.509 24.775-58.516 61.511-12.54 41.855-2.486 82.924 22.456 91.73 1.378.487 2.773.865 4.18 1.137a29.32 29.32 0 0 1-9.353-1.671c-24.942-8.806-34.996-49.875-22.455-91.73 11.847-39.542 39.623-65.64 63.688-60.977Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.018 217.401c12.047-11.208 22.403-28.845 28.166-50.175 11.418-42.256.486-83.004-24.417-91.014-4.186-1.346-8.499-1.681-12.834-1.108 8.117-2.942 16.271-3.032 23.746.297 24.053 10.712 31.971 52.776 17.686 93.953-7.388 21.297-19.269 38.186-32.347 48.047Z"
        fill="#000"
      />
      <path
        d="m152.555 105.744-12.816 77.96c-.509 3.094-1.606 6.319-3.376 9.015-2.851 4.594-7.388 7.792-12.363 8.973l-14.724 3.017-3.636-6.483 2.402-8.438 9.723-2.31c4.193-1.229-9.858 1.153-9.066-3.369 3.008-1.159 4.896-2.95 7.372-5.059 2.475-2.11 3.961-6.51 4.458-13.202 1.634-9.691 3.069-23.608 6.373-43.199l-13.219.963-2.086-5.685 4.556-7.016 30.977-9.947 5.425 4.78Z"
        fill="#000"
      />
      <path
        d="m147.55 99.31-13.252 78.067c-.507 3.101-1.589 6.338-3.326 9.048-2.801 4.618-7.243 7.847-12.107 9.057l-14.393 3.106-1.157-14.937 9.506-2.369c4.1-1.255 7.312-4.876 8.1-9.409l9.875-56.756-21.756 2.223 2.454-12.735 36.056-5.295Z"
        fill="#DAE2EB"
      />
      <path
        d="m108.296 198.434-.18-2.177 12.978-3.185c5.596-1.681 9.971-6.491 11.032-12.495l13.28-75.175-29.715 3.054.558-2.842 32.935-5.086-13.067 77.287c-.519 3.067-1.625 6.272-3.402 8.963-2.863 4.584-7.404 7.81-12.374 9.046l-12.045 2.61Z"
        fill="#fff"
      />
      <path
        d="M173.336 11.666c22.101 12.127 32.005 45.267 28.393 81.681-1.16 11.696-1.657 33.774-2.599 36.555-.943 2.782.944 10.402.059 18.765-1.124 10.621-2.341 22.17-4.004 25.572-12.193 24.946-4.834 24.873-23.707 37.108-2.941 1.907 5.251-23.751 2.427-19.393-6.096 9.405-11.248 17.46-30.54 22.015-19.293 4.555-30.836-3.052-43.55-4.523-12.714-1.471-22.453-3.625-29.217-6.461-7.011-2.94-13.95-8.38-20.816-16.319-19.73-25.007-19.12-72.747 2.465-116.905 25.786-52.755 72.786-82.554 105.789-64.445 4.173 2.29 10.529 3.732 15.3 6.35Z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.423 197.893c11.499-.652 39.959-17.92 57.708-45.64C176.6 126.534 182.957 91 180.166 71.728c-3.285-22.687-7.6-36.556-27.65-48.996 4.775.491 10.025 2.911 14.383 4.993 30.572 14.61 35.906 66.286 13.196 112.92-11.191 22.978-27.354 40.937-44.53 51.497-15.182 9.333-20.815 8.405-24.819 7.555-4.72-1.001-7.494-1.602-8.323-1.804Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.218 130.045c28.115-44.995 29.428-93.87 2.931-109.163-26.496-15.294-70.767 8.783-98.882 53.779-28.115 44.995-29.427 93.869-2.93 109.163 26.495 15.294 70.767-8.784 98.881-53.779Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.218 130.045c28.115-44.995 29.428-93.87 2.931-109.163-26.496-15.294-70.767 8.783-98.882 53.779-28.115 44.995-29.427 93.869-2.93 109.163 26.495 15.294 70.767-8.784 98.881-53.779Z"
        fill="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.485 190.038c27.997 4.432 62.495-20.252 81.882-60.782 22.088-46.177 16.197-95.437-13.158-110.025a38.566 38.566 0 0 0-4.973-2.053c3.901.617 7.676 1.8 11.266 3.584 29.355 14.588 35.246 63.848 13.157 110.025-20.868 43.626-59.243 68.893-88.174 59.251Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.654 25.159c-24.708-2.457-53.718 20.331-68.75 55.966-17.129 40.6-9.628 82.502 16.753 93.591a34.343 34.343 0 0 0 4.45 1.52 34.607 34.607 0 0 1-10.03-2.531c-26.38-11.088-33.88-52.99-16.753-93.59 16.182-38.357 48.557-61.83 74.33-54.956Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.281 170.444c13.781-10.105 26.321-26.82 34.296-47.677 15.798-41.317 7.7-83.237-18.088-93.632-4.334-1.747-8.892-2.492-13.551-2.327 8.886-2.183 17.564-1.502 25.225 2.546 24.651 13.026 29.43 55.982 10.675 95.945-9.7 20.669-23.796 36.489-38.557 45.145Z"
        fill="#000"
      />
      <path
        d="m134.789 58.223-20.393 76.811c-.81 3.048-2.262 6.173-4.392 8.705-3.453 4.33-8.597 7.105-14.04 7.82l-16.07 1.64-3.353-6.828 3.297-8.218 10.639-1.401c4.607-.837-10.686.23-9.45-4.221 3.329-.877 5.509-2.493 8.347-4.372 2.838-1.879 4.808-6.143 5.91-12.792 2.58-9.545 5.303-23.337 10.518-42.63l-14.279-.276-1.756-5.884 5.49-6.594 34.113-7.051 5.419 5.291Z"
        fill="#000"
      />
      <path
        d="m130.637 51.235-20.891 76.774c-.809 3.053-2.25 6.186-4.355 8.732-3.414 4.354-8.484 7.167-13.839 7.924l-15.807 1.764.01-15.034 10.466-1.483c4.533-.871 8.307-4.191 9.541-8.647l15.449-55.795-23.685.196L91.25 53.17l39.387-1.935Z"
        fill="#DAE2EB"
      />
      <path
        d="m79.485 146.719-.014-2.189 14.366-1.979c6.221-1.161 11.375-5.555 13.029-11.448l20.711-73.785-32.539.301.843-2.785 36.208-2.03-20.656 75.911c-.819 3.013-2.289 6.108-4.444 8.63-3.494 4.309-8.697 7.108-14.2 7.882l-13.304 1.492Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.321 195.567c-17.678 31.946-44.436 50.163-67.183 44.21 20.865 1.649 44.029-15.29 60.143-43.361 10.317-17.199 4.713-.506 7.04-.849Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0_1412_4139">
        <path fill="#fff" d="M0 0h256v256H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgSmallTokens.displayName = 'SvgSmallTokens'
const Memo = memo(SvgSmallTokens)
export { Memo as SvgSmallTokens }
