// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const Svg404PatternBottomLeft = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={289}
    height={288}
    viewBox="0 0 289 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_1110_4102)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M.154 152h72v-8h-72v8ZM.154 216h72v-8h-72v8ZM72.154 200h-72v-8h72v8ZM.154 168h72v-8h-72v8ZM72.154 184h-72v-8h72v8Z" />
    </g>
    <path
      d="M144.154 72h-8v8h8v-8ZM120.154 112h8v-8h-8v8ZM112.154 112v-8h-8v8h8ZM112.154 120v-8h8v8h8v8h-8v-8h-8ZM104.154 120v8h8v-8h-8ZM96.154 120h8v-8h-8v8ZM96.154 104h-8v8h8v-8ZM96.154 96h-8v-8h8v8ZM96.154 96v8h8v-8h-8ZM96.154 120v8h-8v-8h8Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.154 80h8v8h8v8h-8v8h8v8h-8v8h8v8h-8v8h8v8h-8v-8h-8v8h-8v-8h-8v8h-8v-8h-8v8h-8v-8h-8v8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v8h8v-8h8v8h8v-8h8v8h8v-8h8v8Zm0 8v-8h-8v8h8Zm0 8v-8h8v8h-8Zm-8 0v-8h-8v8h8Zm0 8v-8h8v8h8v8h-8v8h8v8h-8v8h-8v-8h-8v8h-8v-8h-8v8h-8v-8h-8v-8h8v-8h-8v-8h8v-8h-8v-8h8v-8h8v8h8v-8h8v8h-8v8h8v8h8ZM0 66.36V72h5.64L72 5.64V0h-5.64L0 66.36Zm72-49.08v11.255L28.534 72H17.28L72 17.28Zm0 33.64V39.6L39.6 72h11.32L72 50.92ZM72 72H61.92L72 61.92V72ZM54.72 0H43.466L0 43.465V54.72L54.72 0ZM21.08 0H32.4L0 32.4V21.08L21.08 0ZM0 10.08 10.08 0H0v10.08Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip1_1110_4102)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M144.154 216.027v8.003c-34.862 1.037-62.933 29.109-63.97 63.97H72.18c1.042-39.28 32.694-70.931 71.974-71.973Z" />
      <path d="M88.187 288c1.032-30.442 25.524-54.934 55.967-55.966v8.005C118.13 241.063 97.216 261.976 96.193 288h-8.006Z" />
      <path d="M104.154 288c0-22.091 17.908-40 40-40v8c-17.674 0-32 14.327-32 32h-8Z" />
      <path d="M120.154 288c0-13.255 10.745-24 24-24v8c-8.837 0-16 7.164-16 16h-8ZM136.154 288a8 8 0 0 1 8-8v8h-8Z" />
    </g>
    <g clipPath="url(#clip2_1110_4102)">
      <path d="M288.127 288c-1.042-39.28-32.693-70.931-71.973-71.974V288h71.973Z" fill="#BBD9F6" fillOpacity={0.13} />
    </g>
    <defs>
      <clipPath id="clip0_1110_4102">
        <path fill="#fff" transform="matrix(-1 0 0 1 72.154 144)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip1_1110_4102">
        <path fill="#fff" transform="matrix(-1 0 0 1 144.154 216)" d="M0 0h72v72H0z" />
      </clipPath>
      <clipPath id="clip2_1110_4102">
        <path fill="#fff" transform="matrix(0 -1 -1 0 288.154 288)" d="M0 0h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
))
Svg404PatternBottomLeft.displayName = 'Svg404PatternBottomLeft'
const Memo = memo(Svg404PatternBottomLeft)
export { Memo as Svg404PatternBottomLeft }
