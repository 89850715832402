// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgPatternCircleLines = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_2659_1145)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M0 .027V8.03C34.861 9.067 62.933 37.139 63.97 72h8.004C70.93 32.72 39.28 1.069 0 .027Z" />
      <path d="M55.966 72C54.935 41.557 30.443 17.065 0 16.034v8.005C26.024 25.063 46.937 45.976 47.96 72h8.006Z" />
      <path d="M40 72c0-22.091-17.909-40-40-40v8c17.673 0 32 14.327 32 32h8Z" />
      <path d="M24 72c0-13.255-10.745-24-24-24v8c8.837 0 16 7.163 16 16h8ZM8 72a8 8 0 0 0-8-8v8h8Z" />
    </g>
    <defs>
      <clipPath id="clip0_2659_1145">
        <path fill="#fff" d="M0 0h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgPatternCircleLines.displayName = 'SvgPatternCircleLines'
const Memo = memo(SvgPatternCircleLines)
export { Memo as SvgPatternCircleLines }
