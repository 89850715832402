// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgOtherSignInDialogPatterns = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={378}
    height={270}
    viewBox="0 0 378 270"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.23 216.115H216v-4.23l49.77-49.77H270v4.23l-49.77 49.77Zm36.81-54h-8.441L216 194.715v8.44l41.04-41.04Zm-25.23 0h8.49l-24.3 24.3v-8.49l15.81-15.81Zm-15.81 0v7.56l7.56-7.56H216Zm54 12.96v8.441l-32.599 32.599h-8.441l41.04-41.04Zm0 25.23v-8.49l-24.3 24.3h8.49l15.81-15.81Zm-7.56 15.81 7.56-7.56v7.56h-7.56Z"
      fill="#fff"
    />
    <g clipPath="url(#clip0_1031_506)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M216 114h-54v-6h54v6ZM216 162h-54v-6h54v6ZM162 150h54v-6h-54v6ZM216 126h-54v-6h54v6ZM162 138h54v-6h-54v6Z" />
    </g>
    <g clipPath="url(#clip1_1031_506)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M330 54v54h-6V54h6ZM378 54v54h-6V54h6ZM366 108V54h-6v54h6ZM342 54v54h-6V54h6ZM354 108V54h-6v54h6Z" />
    </g>
    <g clipPath="url(#clip2_1031_506)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M108 222H54v-6h54v6ZM108 270H54v-6h54v6ZM54 258h54v-6H54v6ZM108 234H54v-6h54v6ZM54 246h54v-6H54v6Z" />
    </g>
    <g clipPath="url(#clip3_1031_506)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M323.98 216h-6.002c-.778 26.146-21.832 47.2-47.978 47.978v6.002c29.46-.782 53.199-24.52 53.98-53.98Z" />
      <path d="M270 257.975c22.832-.774 41.201-19.143 41.975-41.975h-6.004c-.768 19.518-16.453 35.203-35.971 35.971v6.004Z" />
      <path d="M270 246c16.569 0 30-13.431 30-30h-6c0 13.255-10.745 24-24 24v6Z" />
      <path d="M270 234c9.941 0 18-8.059 18-18h-6c0 6.627-5.372 12-12 12v6ZM270 222a6 6 0 0 0 6-6h-6v6Z" />
    </g>
    <path
      d="M270 0h6v6h-6V0ZM288 30h-6v-6h6v6ZM294 30v-6h6v6h-6ZM294 36v-6h-6v6h-6v6h6v-6h6ZM300 36v6h-6v-6h6ZM306 36h-6v-6h6v6ZM306 24h6v6h-6v-6ZM306 18h6v-6h-6v6ZM306 18v6h-6v-6h6ZM306 36v6h6v-6h-6Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282 6h-6v6h-6v6h6v6h-6v6h6v6h-6v6h6v6h-6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6V6h6V0h-6v6h-6V0h-6v6h-6V0h-6v6h-6V0h-6v6Zm0 6V6h6v6h-6Zm0 6v-6h-6v6h6Zm6 0v-6h6v6h-6Zm0 6v-6h-6v6h-6v6h6v6h-6v6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6V6h-6v6h-6V6h-6v6h6v6h-6v6h-6ZM54.115 103.77V108h-4.23L.115 58.23V54h4.23l49.77 49.77Zm-54-36.81v8.44l32.6 32.6h8.44L.115 66.96Zm0 25.23V83.7l24.3 24.3h-8.49L.115 92.19Zm0 15.81h7.56l-7.56-7.56V108Zm12.96-54h8.44l32.6 32.6v8.44L13.075 54Zm25.23 0h-8.49l24.3 24.3v-8.49L38.305 54Zm15.81 7.56L46.555 54h7.56v7.56Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip4_1031_506)" fill="#4038FF">
      <path d="M54 .02v6.002C80.146 6.8 101.2 27.854 101.978 54h6.002C107.198 24.54 83.46.802 54 .02Z" />
      <path d="M95.975 54C95.2 31.168 76.832 12.8 54 12.025v6.004C73.518 18.797 89.203 34.483 89.97 54h6.005Z" />
      <path d="M84 54c0-16.569-13.431-30-30-30v6c13.255 0 24 10.745 24 24h6Z" />
      <path d="M72 54c0-9.941-8.059-18-18-18v6c6.627 0 12 5.373 12 12h6ZM60 54a6 6 0 0 0-6-6v6h6Z" />
    </g>
    <defs>
      <clipPath id="clip0_1031_506">
        <path fill="#fff" transform="translate(162 108)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip1_1031_506">
        <path fill="#fff" transform="rotate(-90 216 -108)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip2_1031_506">
        <path fill="#fff" transform="translate(54 216)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip3_1031_506">
        <path fill="#fff" transform="rotate(90 54 270)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip4_1031_506">
        <path fill="#fff" transform="translate(54)" d="M0 0h54v54H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgOtherSignInDialogPatterns.displayName = 'SvgOtherSignInDialogPatterns'
const Memo = memo(SvgOtherSignInDialogPatterns)
export { Memo as SvgOtherSignInDialogPatterns }
