// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgOtherBenefitCardPattern1 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={216}
    height={216}
    viewBox="0 0 216 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.885 166.23V162h4.23l49.77 49.77V216h-4.23l-49.77-49.77Zm54 36.81v-8.441L183.285 162h-8.44l41.04 41.04Zm0-25.23v8.49l-24.3-24.3h8.49l15.81 15.81Zm0-15.81h-7.56l7.56 7.56V162Zm-12.96 54h-8.441l-32.599-32.599v-8.441l41.04 41.04Zm-25.23 0h8.49l-24.3-24.3v8.49l15.81 15.81Zm-15.81-7.56 7.56 7.56h-7.56v-7.56Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <g clipPath="url(#clip0_1505_4274)" fill="#BCD5FA" fillOpacity={0.08}>
      <path d="M54 215.98v-6.002c26.146-.779 47.2-21.832 47.978-47.978h6.002c-.782 29.46-24.52 53.198-53.98 53.98Z" />
      <path d="M95.975 162C95.2 184.832 76.832 203.201 54 203.974v-6.004c19.518-.767 35.203-16.452 35.97-35.97h6.005Z" />
      <path d="M84 162c0 16.568-13.431 30-30 30v-6c13.255 0 24-10.745 24-24h6Z" />
      <path d="M72 162c0 9.941-8.059 18-18 18v-6c6.627 0 12-5.373 12-12h6ZM60 162a6 6 0 0 1-6 6v-6h6Z" />
    </g>
    <g clipPath="url(#clip1_1505_4274)">
      <path d="M162 161.98c-29.46-.781-53.199-24.52-53.98-53.98H162v53.98Z" fill="#BCD5FA" fillOpacity={0.08} />
    </g>
    <path
      d="M216 108h-6v-6h6v6ZM198 78h6v6h-6v-6ZM192 78v6h-6v-6h6ZM192 72v6h6v-6h6v-6h-6v6h-6ZM186 72v-6h6v6h-6ZM180 72h6v6h-6v-6ZM180 84h-6v-6h6v6ZM180 90h-6v6h6v-6ZM180 90v-6h6v6h-6ZM180 72v-6h-6v6h6Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M204 102h6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6h6v6h-6v6h6v6h-6v6h6v6h-6v6h6v6h-6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6Zm0-6v6h-6v-6h6Zm0-6v6h6v-6h-6Zm-6 0v6h-6v-6h6Zm0-6v6h6v-6h6v-6h-6v-6h6v-6h-6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6h-6v6h6v6h-6v6h6v6h-6v6h6v6h6v-6h6v6h6v-6h-6v-6h6v-6h6Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      d="M0 108h6v6H0v-6ZM18 138h-6v-6h6v6ZM24 138v-6h6v6h-6ZM24 144v-6h-6v6h-6v6h6v-6h6ZM30 144v6h-6v-6h6ZM36 144h-6v-6h6v6ZM36 132h6v6h-6v-6ZM36 126h6v-6h-6v6ZM36 126v6h-6v-6h6ZM36 144v6h6v-6h-6Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 114H6v6H0v6h6v6H0v6h6v6H0v6h6v6H0v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6h-6v-6h-6v6Zm0 6v-6h6v6h-6Zm0 6v-6H6v6h6Zm6 0v-6h6v6h-6Zm0 6v-6h-6v6H6v6h6v6H6v6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h-6v6h-6v-6h-6v6h6v6h-6v6h-6Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <g clipPath="url(#clip2_1505_4274)">
      <path d="M54 54.02c29.46.781 53.199 24.52 53.98 53.98H54V54.02Z" fill="#BCD5FA" fillOpacity={0.08} />
    </g>
    <g clipPath="url(#clip3_1505_4274)" fill="#BCD5FA" fillOpacity={0.08}>
      <path d="M162 .02v6.002C135.854 6.8 114.8 27.854 114.022 54h-6.002C108.802 24.54 132.54.8 162 .02Z" />
      <path d="M120.025 54c.774-22.832 19.143-41.201 41.975-41.975v6.004c-19.518.768-35.203 16.453-35.971 35.97h-6.004Z" />
      <path d="M132 54c0-16.569 13.431-30 30-30v6c-13.255 0-24 10.745-24 24h-6Z" />
      <path d="M144 54c0-9.941 8.059-18 18-18v6c-6.627 0-12 5.373-12 12h-6ZM156 54a6 6 0 0 1 6-6v6h-6Z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.115 49.77V54h-4.23L.115 4.23V0h4.23l49.77 49.77Zm-54-36.81v8.44l32.6 32.6h8.44L.115 12.96Zm0 25.23V29.7l24.3 24.3h-8.49L.115 38.19Zm0 15.81h7.56l-7.56-7.56V54Zm12.96-54h8.44l32.6 32.6v8.44L13.075 0Zm25.23 0h-8.49l24.3 24.3v-8.49L38.305 0Zm15.81 7.56L46.555 0h7.56v7.56Z"
      fill="#C2E0FF"
      fillOpacity={0.2}
    />
    <defs>
      <clipPath id="clip0_1505_4274">
        <path fill="#fff" transform="matrix(1 0 0 -1 54 216)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip1_1505_4274">
        <path fill="#fff" transform="rotate(-180 81 81)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip2_1505_4274">
        <path fill="#fff" transform="translate(54 54)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip3_1505_4274">
        <path fill="#fff" transform="matrix(-1 0 0 1 162 0)" d="M0 0h54v54H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgOtherBenefitCardPattern1.displayName = 'SvgOtherBenefitCardPattern1'
const Memo = memo(SvgOtherBenefitCardPattern1)
export { Memo as SvgOtherBenefitCardPattern1 }
