// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgOtherThumbsUpIllustrationSvg = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={320}
    height={108}
    viewBox="0 0 320 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M79 0h6v6h-6V0ZM97 30h-6v-6h6v6ZM103 30v-6h6v6h-6ZM103 36v-6h-6v6h-6v6h6v-6h6ZM109 36v6h-6v-6h6ZM115 36h-6v-6h6v6ZM115 24h6v6h-6v-6ZM115 18h6v-6h-6v6ZM115 18v6h-6v-6h6ZM115 36v6h6v-6h-6Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91 6h-6v6h-6v6h6v6h-6v6h6v6h-6v6h6v6h-6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6V6h6V0h-6v6h-6V0h-6v6h-6V0h-6v6h-6V0h-6v6Zm0 6V6h6v6h-6Zm0 6v-6h-6v6h6Zm6 0v-6h6v6h-6Zm0 6v-6h-6v6h-6v6h6v6h-6v6h6v6h6v-6h6v6h6v-6h6v6h6v-6h6v-6h-6v-6h6v-6h-6v-6h6v-6h-6V6h-6v6h-6V6h-6v6h6v6h-6v6h-6Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip0_1310_4365)">
      <path d="M79 107.98C49.54 107.199 25.802 83.46 25.02 54H79v53.98Z" fill="#BBD9F6" fillOpacity={0.13} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.885 58.23V54h4.23l49.77 49.77V108h-4.23l-49.77-49.77Zm54 36.81V86.6l-32.6-32.6h-8.44l41.04 41.04Zm0-25.23v8.49l-24.3-24.3h8.49l15.81 15.81Zm0-15.81h-7.56l7.56 7.56V54Zm-12.96 54h-8.441l-32.599-32.6v-8.44l41.04 41.04Zm-25.23 0h8.49l-24.3-24.3v8.49l15.81 15.81Zm-15.81-7.56 7.56 7.56h-7.56v-7.56Z"
      fill="#BBD9F6"
      fillOpacity={0.13}
    />
    <g clipPath="url(#clip1_1310_4365)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M133 54.02v6.002c26.146.778 47.2 21.832 47.978 47.978h6.002c-.782-29.46-24.52-53.199-53.98-53.98Z" />
      <path d="M174.975 108c-.774-22.832-19.143-41.201-41.975-41.975v6.004c19.518.768 35.203 16.453 35.971 35.971h6.004Z" />
      <path d="M163 108c0-16.569-13.431-30-30-30v6c13.255 0 24 10.745 24 24h6Z" />
      <path d="M151 108c0-9.941-8.059-18-18-18v6c6.627 0 12 5.372 12 12h6ZM139 108a6 6 0 0 0-6-6v6h6Z" />
    </g>
    <g clipPath="url(#clip2_1310_4365)" fill="#BBD9F6" fillOpacity={0.13}>
      <path d="M241 6h-54V0h54v6ZM241 54h-54v-6h54v6ZM187 42h54v-6h-54v6ZM241 18h-54v-6h54v6ZM187 30h54v-6h-54v6Z" />
    </g>
    <path
      d="M130.711 97.624c-8.072 0-14.615-5.227-14.615-11.675 0-.387.024-.771.071-1.153-4.392-2.06-7.215-5.855-7.215-10.07 0-2.212.775-4.33 2.192-6.152-2.006-1.967-3.144-4.446-3.144-7.069 0-2.638 1.151-5.13 3.18-7.104-1.138-1.682-1.751-3.579-1.751-5.546 0-6.448 6.543-11.675 14.614-11.675h14.832c.142 0 2.337-.022 2.241-.022h2.092c-.713-.887-9.558-11.248-9.479-17.892.086-7.175 7.106-10.645 7.766-10.913 3.021-1.23 7.342-.004 10.503 3.665 3.846 4.466 9.007 7.626 11.941 11.45.728.948 2.707 3.56 2.802 3.683.863 1.132 1.423 1.835 1.911 2.396 2.189 2.513 5.135 4.808 8.853 6.881 3.693 2.06 6.884 5.323 9.669 9.502H212v46.558h-29.326c-5.847 3.53-14.196 6.451-20.486 7.205-6.114.732-15.231.03-27.479-2.069h-3.998Z"
      fill="#000"
    />
    <path
      d="M156.387 22.508a7.398 7.398 0 0 1-.307 2.375 6.09 6.09 0 0 1-.341.882c.732.016 1.427-.457 1.811-1.342a3.2 3.2 0 0 0 .123-.331c1.385 1.706 3.821 4.648 3.902 4.754.916 1.2 1.523 1.964 2.09 2.615 2.569 2.95 5.973 5.602 10.197 7.958 6.067 3.384 8.446 8.358 9.236 9.625l.012.019c1.269 2.983 1.731 7.092 1.073 11.503a27.213 27.213 0 0 1-.79 3.607c2.435-1.346 4.557-4.238 5.464-7.862.54-2.158.569-4.258.177-6.068h17.549v18.951c0 .7-.038 1.392-.112 2.073-6.739.035-13.037.746-16.249 1.984-1.486.573-2.139 1.193-2.047 1.806 3.609-.654 10.104-1.497 12.45-1.115 2.346.383 4.56 2.545 3.934 3.785-3.122 6.184-9.533 10.426-16.934 10.426h-3.157l.119-.214c1.149-2.118 1.376-4.26.79-5.84-.376.79-.884 1.547-1.382 2.394-6.12 10.409-18.795 10.456-22.611 10.913-3.607.433-8.7.269-15.239-.504a7.312 7.312 0 0 0 4.105-6.574 7.286 7.286 0 0 0-.694-3.113c2.384-4.283 10.778-10.056 14.109-11.273a26.195 26.195 0 0 1-3.291-1.495c-2.351-1.269-6.77-4.211-5.249-8.274 1.521-4.062-1.521-7.93-3.822-9.428 1.731-1.127 4.227-2.64 5.447-5.682.402-1.002 1.594-7.047-3.25-11.38-3.049-2.728-6.688-4.355-10.725-9.75-4.875-5.417-7.15-13.482 0-15.705a.654.654 0 0 1 .062-.003c.177-.005 1.947 2.035 2.992 3.25 3.594 4.173 7.683 3.867 10.558 7.033Z"
      fill="#DAE2EB"
    />
    <path
      d="M156.527 42.167c-.536-1.522-1.47-3.092-3.027-4.485-3.049-2.727-6.688-4.354-10.725-9.75-4.741-5.267-7.023-13.039-.57-15.507-.222 4.649-.743 9.32 2.628 13.572 4.723 5.958 12.992 13.503 11.917 16.019-.034.08-.11.129-.223.151Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.617 83.724c1.8-1.853 5.842-6.978 12.091-10.292 6.25-3.313 13.484-3.012 15.251.61.394.808-.66 4.541-3.622 5.4-3.386.982-11.122-.875-14.418.732-3.48 1.697-9.518 3.772-9.302 3.55Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m142.653 10.961 6.59 5.8c.78.687.976 1.697.438 2.257l-.649.675c-.538.56-1.606.456-2.386-.23l-2.824-2.486c-2.08-1.83-2.603-4.524-1.169-6.016Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m143.711 12.294 5.563 5.06c.659.598.829 1.474.38 1.956l-.541.581c-.448.482-1.345.387-2.004-.212l-2.384-2.169c-1.755-1.596-2.209-3.932-1.014-5.216Z"
      fill="#fff"
    />
    <path
      d="M117.75 70.182a4.333 4.333 0 0 0-4.333 4.334v2.166a4.333 4.333 0 0 0 4.333 4.334h27.625a5.417 5.417 0 1 0 0-10.834H117.75ZM125.333 83.724A4.333 4.333 0 0 0 121 88.057v.542a4.333 4.333 0 0 0 4.333 4.333h17.605a4.605 4.605 0 0 0 0-9.208h-17.605ZM117.208 56.099a4.333 4.333 0 0 0-4.333 4.333v2.709a4.333 4.333 0 0 0 4.333 4.333h29.521a5.688 5.688 0 0 0 0-11.375h-29.521ZM119.187 42.016c-2.29 0-4.145 1.94-4.145 4.333v2.708c0 2.393 1.855 4.334 4.145 4.334h26.165c3.004 0 5.44-2.547 5.44-5.688 0-3.141-2.436-5.687-5.44-5.687h-26.165Z"
      fill="#DAE2EB"
    />
    <path
      d="M151.333 53.39h-3.506c-2.251 0-4.077-2.182-4.077-4.874 0-2.693 1.826-4.875 4.077-4.875h3.506v9.75ZM152.417 67.474h-3.005c-1.931 0-3.495-2.183-3.495-4.875s1.564-4.875 3.495-4.875h3.005v9.75ZM150.792 81.557h-3.005c-1.931 0-3.495-2.182-3.495-4.875 0-2.692 1.564-4.875 3.495-4.875h3.005v9.75ZM148.083 92.932h-2.504c-1.608 0-2.912-1.697-2.912-3.791 0-2.095 1.304-3.792 2.912-3.792h2.504v7.583Z"
      fill="#000"
    />
    <path
      d="M149.97 65.307h-1.085c-1.34 0-2.427-1.576-2.427-3.52 0-1.945 1.087-3.521 2.427-3.521h.976c.701 0 1.322.449 1.543 1.114.314.947.471 1.847.471 2.7 0 .769-.128 1.493-.383 2.174a1.625 1.625 0 0 1-1.522 1.053ZM148.345 79.39h-1.085c-1.34 0-2.427-1.576-2.427-3.52 0-1.945 1.087-3.521 2.427-3.521h.976c.701 0 1.322.449 1.543 1.114.314.948.471 1.848.471 2.7 0 .769-.128 1.493-.383 2.174a1.625 1.625 0 0 1-1.522 1.053ZM145.798 91.307h-.648c-1.072 0-1.942-1.212-1.942-2.708 0-1.496.87-2.708 1.942-2.708h.555c.717 0 1.35.47 1.556 1.156.187.62.281 1.212.281 1.778 0 .46-.062.898-.186 1.317a1.625 1.625 0 0 1-1.558 1.165ZM148.858 51.766h-1.056c-1.34 0-2.427-1.698-2.427-3.792s1.087-3.792 2.427-3.792h.949c.711 0 1.339.462 1.551 1.14.326 1.043.49 2.032.49 2.968 0 .85-.135 1.65-.405 2.4a1.623 1.623 0 0 1-1.529 1.076Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.406 51.78c.731.02 1.426-.583 1.811-1.71.598-1.756.217-4.117-.852-5.274-.327-.355-.681-.555-1.032-.612.761 1.569.962 4.165.414 6.472a9.336 9.336 0 0 1-.341 1.125ZM118.779 51.78c-.549.02-1.07-.584-1.358-1.712-.449-1.755-.163-4.116.638-5.274.246-.354.511-.555.774-.612-.57 1.569-.721 4.165-.31 6.473.073.406.159.782.256 1.124ZM116.071 65.863c-.549.02-1.07-.584-1.359-1.712-.448-1.755-.163-4.116.639-5.273.245-.355.511-.555.774-.612-.571 1.568-.721 4.165-.31 6.472.073.407.159.782.256 1.125ZM116.071 79.404c-.549.02-1.07-.583-1.359-1.71-.448-1.756-.163-4.117.639-5.275.245-.354.511-.555.774-.612-.571 1.569-.721 4.165-.31 6.473.073.406.159.782.256 1.124ZM123.654 91.863c-.549.017-1.07-.5-1.358-1.464-.449-1.502-.163-3.522.638-4.512.246-.303.511-.475.774-.524-.57 1.342-.721 3.564-.31 5.538.073.348.159.67.256.962ZM137.846 79.39c.549-.135 1.07-.886 1.358-2.096.449-1.882.163-4.162-.638-5.092-.246-.285-.511-.41-.774-.393.57 1.407.721 3.96.31 6.385-.073.427-.159.827-.256 1.197ZM138.406 65.865c.731.02 1.426-.582 1.811-1.71.598-1.754.217-4.115-.852-5.274-.327-.355-.681-.556-1.032-.614.761 1.57.962 4.167.414 6.473a9.324 9.324 0 0 1-.341 1.125ZM138.37 91.849c.365-.116.713-.76.905-1.797.299-1.614.109-3.568-.425-4.365-.164-.245-.341-.352-.517-.337.381 1.206.481 3.395.207 5.473-.048.366-.106.709-.17 1.026Z"
      fill="#000"
    />
    <defs>
      <clipPath id="clip0_1310_4365">
        <path fill="#fff" transform="rotate(-180 39.5 54)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip1_1310_4365">
        <path fill="#fff" transform="translate(133 54)" d="M0 0h54v54H0z" />
      </clipPath>
      <clipPath id="clip2_1310_4365">
        <path fill="#fff" transform="translate(187)" d="M0 0h54v54H0z" />
      </clipPath>
    </defs>
  </svg>
))
SvgOtherThumbsUpIllustrationSvg.displayName = 'SvgOtherThumbsUpIllustrationSvg'
const Memo = memo(SvgOtherThumbsUpIllustrationSvg)
export { Memo as SvgOtherThumbsUpIllustrationSvg }
