// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgRevenueSharePlaceholder = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={256} height={96} viewBox="0 0 256 96" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path fill="#424E57" d="M8 8h43v8H8zM8 18h34v4H8zM85.667 13h33v4h-33z" />
    <circle cx={75.667} cy={15} r={6} fill="#424E57" />
    <path fill="#424E57" d="M153.333 13h33v4h-33zM205 8h43v8h-43zM214 18h34v4h-34z" />
    <path fill="#343D44" d="M8 30h43v8H8zM8 40h34v4H8zM85.667 35h33v4h-33z" />
    <circle cx={75.667} cy={37} r={6} fill="#343D44" />
    <path fill="#343D44" d="M153.333 35h33v4h-33zM205 30h43v8h-43zM214 40h34v4h-34z" />
    <path fill="#272D33" d="M8 52h43v8H8zM8 62h34v4H8zM85.667 57h33v4h-33z" />
    <circle cx={75.667} cy={59} r={6} fill="#272D33" />
    <path fill="#272D33" d="M153.333 57h33v4h-33zM205 52h43v8h-43zM214 62h34v4h-34z" />
    <path fill="#181C20" d="M8 74h43v8H8zM8 84h34v4H8zM85.667 79h33v4h-33z" />
    <circle cx={75.667} cy={81} r={6} fill="#181C20" />
    <path fill="#181C20" d="M153.333 79h33v4h-33zM205 74h43v8h-43zM214 84h34v4h-34z" />
  </svg>
))
SvgRevenueSharePlaceholder.displayName = 'SvgRevenueSharePlaceholder'
const Memo = memo(SvgRevenueSharePlaceholder)
export { Memo as SvgRevenueSharePlaceholder }
