// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSmallMoneroModified = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width={256}
    height={256}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M120.804 9.414c15.979-9.988 33.821-12.815 49.398-4.635 1.439.745 3.296 1.39 5.61 2.193l.117.04c2.23.775 4.84 1.687 7.162 2.908 15.561 8.164 24.496 25.83 27.261 46.18 2.781 20.458-.526 44.377-10.233 66.311-9.159 20.713-22.856 37.843-38.576 48.177a43.56 43.56 0 0 1-20.654 7.432l-.009.001-.01.001c-4.019.345-8.066.02-11.978-.962-10.537-1.23-18.964-3.035-25.094-5.522-6.6-2.657-13.008-7.473-19.258-14.173l-.056-.06-.052-.062c-9.426-10.989-14.343-26.648-14.966-43.876-.624-17.272 3.048-36.427 11.15-54.766l.002-.004c9.688-21.873 24.212-39.2 40.186-49.184Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.18 163.926c8.821-.469 30.541-14.597 43.975-37.438 12.459-21.192 17.107-50.576 14.839-66.552-2.673-18.805-6.079-30.313-21.556-40.735 3.673.434 7.76 2.473 11.071 4.218 23.574 12.28 28.041 55.114 10.89 93.576-8.432 18.961-20.694 33.731-33.826 42.375-11.588 7.636-15.917 6.829-18.969 6.074-3.647-.824-5.786-1.345-6.424-1.518Z"
      fill="#DAE2EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.647 109.775c16.762-38.184 11.442-79.136-11.891-91.494-23.332-12.357-55.839 8.574-72.61 46.74-16.77 38.167-11.442 79.136 11.891 91.494 23.332 12.358 55.839-8.557 72.61-46.74Z"
      fill="#DAE2EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.647 109.775c16.762-38.184 11.442-79.136-11.891-91.494-23.332-12.357-55.839 8.574-72.61 46.74-16.77 38.167-11.442 79.136 11.891 91.494 23.332 12.358 55.839-8.557 72.61-46.74Z"
      fill="#DAE2EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.168 24.868c-19.021-2.178-41.164 16.488-52.459 45.916-12.865 33.506-6.777 68.236 13.597 77.565 1.116.515 2.269.944 3.449 1.284a25.318 25.318 0 0 1-7.76-2.152c-20.375-9.338-26.462-44.059-13.589-77.565 12.158-31.65 36.879-50.888 56.753-45.048h.009Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.029 158.493c21.617 3.697 48.148-16.705 62.944-50.228 16.875-38.184 12.158-78.971-10.545-91.077a29.072 29.072 0 0 0-3.845-1.736 28.986 28.986 0 0 1 8.7 2.977c22.703 12.15 27.428 52.884 10.545 91.12-15.943 36.083-45.458 56.963-67.816 48.953l.017-.009Z"
      fill="#fff"
    />
    <path
      d="M137.639 142.508c10.735-8.252 20.34-21.946 26.238-39.051 11.675-33.914 4.354-68.435-16.383-77.096a25.718 25.718 0 0 0-10.812-1.979c7.01-1.736 13.916-1.154 20.09 2.213 19.832 10.822 24.566 46.176 10.511 78.971-7.277 16.957-18.107 29.904-29.679 36.96l.035-.018Z"
      fill="#000"
    />
    <path
      d="M94.738 111.534c3.163-.791 6.378-1.609 9.635-2.409.315-.078.4-.226.468-.721a142226.4 142226.4 0 0 1 6.37-45.717l.094-.67c.144 0 .187.183.255.287l17.859 29.18c.255.426.255.426.655-.183a46868.378 46868.378 0 0 0 30.616-45.986c.137-.2.256-.418.383-.626h.094l-.094.66-7.042 50.578c-.119.87-.178.756.392.608l12.34-3.069a101.355 101.355 0 0 1-4.253 10.512c-.442.956-.927 1.895-1.369 2.852a.943.943 0 0 1-.672.635 2642.58 2642.58 0 0 0-16.745 3.799c-.366.078-.442 0-.366-.591a9448.21 9448.21 0 0 0 4.252-30.719l.111-.817c-.187.27-.306.452-.434.626l-19.756 29.214c-.272.418-.391.444-.57.14-4.031-6.852-8-13.553-11.906-20.103a2.863 2.863 0 0 1-.17-.365l-.094.07-.085.608-4.005 28.693c-.094.687-.094.687-.519.783a3562.025 3562.025 0 0 0-13.607 3.104c-.247.052-.366 0-.443-.366a59.611 59.611 0 0 1-1.394-9.816 1.416 1.416 0 0 1 0-.191Z"
      fill="#DAE2EB"
    />
    <path
      d="M230.296 148.109c-4.675-4.381-10.692-6.213-17.483-5.711l-.045.003-.826.08-.036.004c-5.768.665-9.76 3.449-12.417 5.302l-.102.072-.477.323a20.453 20.453 0 0 1-4.319 1.858l-.078.023-.076.026c-.983.34-2.042.714-3.127 1.143-3.382 1.324-6.655 2.796-9.758 4.191l-.775.349-.006.003c-5.005 2.259-9.587 4.317-14.087 5.785a21.228 21.228 0 0 0-6.942-4.449c-5.66-2.316-11.87-2.953-18.8-2.154l-.032.004-.033.004a106.669 106.669 0 0 0-10.88 2.018l-3.189.691-.012.002c-2.158.454-4.222.87-6.135 1.1l-.03.003-.029.004a15.3 15.3 0 0 1-3.629.059 16.793 16.793 0 0 1-2.918-.722l-.048-.017-.313-.098-.007-.002a745.437 745.437 0 0 1-15.107-4.896l-2.149-.719-.04-.012a38.047 38.047 0 0 0-16.037-1.414c-10.963 1.27-20.572 6.486-26.581 10.561a64.995 64.995 0 0 0-7.132 5.293c-2.523 2.172-5.33 4.988-6.918 8.017-.427.645-1.229 1.827-1.79 2.632-2.772 2.533-7.712 7.992-15.568 17.009l-1.865 2.14 33.543 55.906.007.011a7.207 7.207 0 0 0 6.347 3.466 7.214 7.214 0 0 0 6.152-3.797l.47-.868v-.037c3.265-5.876 6.339-10.387 9.186-13.598 3.008-3.393 5.49-5.022 7.387-5.563a16.827 16.827 0 0 1 1.902-.353l.068-.009.069-.011a57.79 57.79 0 0 1 2.653-.351 159.343 159.343 0 0 1 22.902-.579l.025.001.024.001c1.512.051 3.063.146 4.751.261 4.58.416 9.188.415 13.768-.004l.017-.002.017-.001a34.29 34.29 0 0 0 12.79-3.909l.042-.023.041-.023a90.501 90.501 0 0 0 8.334-5.364l.041-.028c2.254-1.565 4.186-2.905 6.117-3.972l.005-.003c4.403-2.44 8.934-4.574 13.89-6.907l.005-.002 1.655-.774.019-.009a270.733 270.733 0 0 0 45.694-27.825c.378-.255.769-.516 1.17-.785l.003-.001c2.733-1.827 5.938-3.97 8.673-6.515 3.235-3.012 6.14-6.868 7.171-12.006 1.229-5.978-1.243-11.18-5.142-14.832Z"
      fill="#000"
    />
    <path
      d="M217.238 154.982c-2.426-2.301-5.491-2.872-8.65-2.635-3.337.246-5.516 1.967-8.164 3.821-2.648 1.853-6.206 2.793-9.297 4.031-10.318 4.12-20.33 10.005-31.261 12.122-.698.141-1.158.114-1.439-.764-.88-2.658-2.89-4.755-5.457-5.692-8.224-3.417-17.112-.632-25.37 1.116-4.172.878-8.692 1.835-12.966 1.273a27.061 27.061 0 0 1-4.946-1.221c-8.871-2.81-15.171-5.015-16.593-5.446-18.525-5.999-40.584 13.369-41.58 16.69-.06.193-2.97 4.541-3.15 4.673-1.498 1.177-6.027 6.201-11.774 12.833l8.854 14.932c6.575 11.222 20.526 15.148 31.713 8.925a18.645 18.645 0 0 1 4.171-1.757 25.494 25.494 0 0 1 3.142-.624c8.76-1.308 17.623-1.291 26.451-1.01 8.258.255 16.967 2.003 24.578-2.213 4.819-2.635 9.059-6.377 13.894-9.1 5.049-2.837 10.285-5.271 15.495-7.783 6.811-3.241 13.621-6.553 19.853-10.857 4.129-2.846 8.037-6.025 12.081-9.012 4.452-3.311 28.324-14.818 20.415-22.302Z"
      fill="#DAE2EB"
    />
    <path
      d="M217.008 156.644c-2.423-2.238-5.484-2.794-8.638-2.563-3.333.239-5.51 1.914-8.154 3.716-2.645 1.803-6.199 2.717-9.285 3.921-10.305 4.007-20.304 9.73-31.222 11.789-.697.137-1.156.111-1.437-.743a8.913 8.913 0 0 0-5.45-5.536c-8.213-3.323-17.09-.615-25.338 1.085-4.166.854-8.681 1.785-12.949 1.239a27.668 27.668 0 0 1-4.94-1.188c-8.86-2.733-15.152-4.878-16.572-5.296-18.501-5.835-40.532 13.002-41.527 16.231-.06.188-2.967 4.416-3.146 4.545-1.496 1.144-6.02 6.031-11.759 12.48l9.098 14.984c6.445-.709 11.19-.974 9.285-4.211-6.98-11.96 4.251-23.066 4.438-23.177 12.593-7.099 14.506-19.511 33.008-13.668a90.31 90.31 0 0 1 11.606 2.862c1.539.478 10.781.897 12.753.675 3.478-.393 8.197-1.965 12.372-2.828 8.239-1.708 18.391-5.638 27.616 1.111 2.253 1.64 1.14 2.862 2.041 5.587.281.854.74.854 1.437.751 10.917-2.067 20.916-7.782 31.221-11.797 3.087-1.204 6.582-2.076 9.285-3.921 2.704-1.845 4.821-3.477 8.154-3.716 3.155-.231 6.216.325 8.63 2.563.213.196.4.384.57.564 1.242-2.187.901-3.605-1.097-5.459Z"
      fill="#fff"
    />
    <path
      d="M159.729 171.682c2.973-2.001 7.595-2.843 9.09-1.03 1.666 2.001-.974 6.673-1.299 7.266-4.023 7.146-11.105 6.416-22.596 13.253-12.028 7.163-12.31 10.667-17.94 10.59-6.032-.077-7.766-4.131-20.691-7.61-4.272-1.151-7.254-1.554-7.22-2.07.086-1.116 15.976 3.436 32.08-3.607 7.125-3.101 10.055-6.476 16.77-8.323 3.263-.902 7.287-2.937 9.243-6.012a7.72 7.72 0 0 1 2.563-2.457Z"
      fill="#000"
    />
  </svg>
))
SvgSmallMoneroModified.displayName = 'SvgSmallMoneroModified'
const Memo = memo(SvgSmallMoneroModified)
export { Memo as SvgSmallMoneroModified }
